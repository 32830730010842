import { Grid, useMediaQuery, useTheme } from '@mui/material'
import IntroPage from './IntroPage'
import Banner from './Banner'
import VisitSummary from './VisitSummary'
import { ContainedImage } from '../../../../Components/ResponsiveImage'
import groupPhoto from '../../../../Resources/Orphanages/OrphanageResource.png'

const June2024Page = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <>
            <IntroPage />
            <Banner smDown={smDown} />
            <VisitSummary />
            <Banner smDown={smDown} text="Thank You All!" />
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <ContainedImage
                        label={`${groupPhoto}`}
                        source={groupPhoto}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default June2024Page
