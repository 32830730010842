import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { primaryColor } from '../../pallete'
import { CardProp } from '../OpenerTypes'
import { ContainedImage } from '../../ResponsiveImage'
import OpeningCard from '../DisplayCard'
import useBreakPointDebugger from '../../../Functions/useBreakPointDebugger'
import { ThemedRouteButton } from '../../Button'

type Props = {
    image: string
    cardProps: CardProp
}
const SeperatePage = ({ image, cardProps }: Props) => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))

    const { cardSize, buttonProps, cardTransparency, titleProps, cardText } =
        cardProps || {}

    return (
        <Grid
            container
            sx={{ backgroundColor: primaryColor }}
            justifyContent="center"
        >
            <Grid
                item
                xs={12}
                md={11}
                lg={10}
                container
                justifyContent="center"
                /*   */
            >
                <Grid
                    item
                    xs={12}
                    md={5}
                    lg={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: smDown ? 'center' : 'flex-end',
                        order: smDown ? 2 : 1,
                    }}
                >
                    {smDown ? (
                        <Card
                            sx={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: '#F8F8F8',
                            }}
                            elevation={0}
                        >
                            <CardHeader
                                title={titleProps?.title}
                                titleTypographyProps={{
                                    variant: 'h4',
                                    sx: {
                                        overflowWrap: 'break-word',
                                        fontFamily: 'Canva Sans',
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                            <CardContent
                                sx={{
                                    fontFamily: 'times-new-roman',
                                    fontSize: 22,
                                    overflow: 'auto',
                                }}
                            >
                                {cardText}
                            </CardContent>
                            <CardActions>
                                <ThemedRouteButton
                                    title={buttonProps?.title as string}
                                    routePath={buttonProps?.route as string}
                                    download={buttonProps?.download}
                                />
                            </CardActions>
                        </Card>
                    ) : (
                        <OpeningCard
                            cardSize={cardSize}
                            cardText={cardText}
                            buttonProps={buttonProps}
                            cardTransparency={cardTransparency}
                            titleProps={titleProps}
                            seperate
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7}
                    lg={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        order: smDown ? 1 : 2,
                    }}
                >
                    <ContainedImage label={`${image}`} source={image} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SeperatePage
