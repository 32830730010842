import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { OpeningPage } from '../../../../Components/OpenerPage'
import BabyHolding from '../../../../Resources/Racheal/RachealandMomHoldingBabies.png'
import {
    CardPosition,
    CardProp,
} from '../../../../Components/OpenerPage/OpenerTypes'
import { useThemeBreakPoints } from '../../../../Functions/useBreakPointDebugger'

const IntroPage = () => {
    const theme = useTheme()
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
    const { xsMatch } = useThemeBreakPoints()

    const OpeningPageCard: CardProp = {
        titleProps: {
            title: 'Vietnam Visit 2024',
        },
        cardSize: lgUp ? 'large' : 'medium',
        cardText: textForPage,
        buttonProps: {
            title: 'See more details here',
            route: '/updates',
            download: true,
        },
    }

    return (
        <Grid container sx={{ height: xsMatch ? undefined : '100%' }}>
            <Grid item xs={12}>
                <OpeningPage
                    image={BabyHolding}
                    cardPosition={CardPosition.right}
                    cardProps={OpeningPageCard}
                    seperate
                />
            </Grid>
        </Grid>
    )
}

export default IntroPage

const textForPage =
    'In June 2024, we visited three orphanages — Mai Am Thien Duyen, Co So Khuyet Tat Thien Phuoc Nhan Ai, and Mai Am Hoa Hong — where we provided essential supplies such as rice, milk, sugar, cooking items, and more.'
