import { Button, Grid } from '@mui/material'
import { addDonation } from '../Functions/DonateTotalMutation'
import { SubmitHandler, useForm } from 'react-hook-form'
import { DonateData } from '../../DonatePage/Functions/DonateTypes'
import FormText from './FormText'

const DonateForm = () => {
    const { handleSubmit, control } = useForm<DonateData>()

    const onSubmit: SubmitHandler<DonateData> = (data) => {
        console.log({ data })

        /*   addDonation({ newDonation: data }) */
    }

    return (
        <Grid
            container
            sx={{ border: 1 }}
            justifyContent="center"
            alignItems="center"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item>
                    <FormText name="donateValue" />
                </Grid>
                <Grid item>
                    <FormText name="date" />
                </Grid>
                <Grid item>
                    <Button variant="contained" type="submit">
                        Submit
                    </Button>
                </Grid>
            </form>
        </Grid>
    )
}

export default DonateForm
