import { Grid, CardContent, Card } from '@mui/material'
import { ContainedImage } from '../../../../Components/ResponsiveImage'
import { useThemeBreakPoints } from '../../../../Functions/useBreakPointDebugger'

type StoryBoardProp = {
    image: string
    text: string
    imageLeft: boolean
}

const StoryBoard = ({ image, text, imageLeft }: StoryBoardProp) => {
    const { xsMatch } = useThemeBreakPoints()

    return (
        <Grid item xs={12} container sx={{ pt: 5, pb: 5 }}>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{ order: imageLeft ? 1 : xsMatch ? 1 : 2 }}
            >
                <ContainedImage label={`${image}`} source={image} />
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{ order: imageLeft ? 2 : xsMatch ? 2 : 1 }}
            >
                <Card
                    sx={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: '#F8F8F8',
                        /* overflowY: 'auto', */
                    }}
                    elevation={0}
                >
                    <CardContent
                        sx={{
                            fontFamily: 'times-new-roman',
                            fontSize: 22,
                            textAlign: 'center',
                            height: '100%',
                            alignItems: 'center',
                            display: 'flex',
                            pl: 4,
                            pr: 4,
                        }}
                    >
                        {text}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default StoryBoard
