import { useGetDonateDate } from '../DonatePage/Functions/useGetDonateData'
import AbouttheFounder from './AbouttheFounder'
import ImagePage from './ImagePage'
import OverViewPage from './OverviewPage'
import TotalDonations from './TotalDonations'
import UpdatesPage from './UpdatesPage'

const HomePage = () => {
    /* const data = useGetDonateDate() */

    return (
        <>
            <ImagePage />
            <OverViewPage />
            <AbouttheFounder />
            <UpdatesPage />
            <TotalDonations /* data={data?.[0]} */ />
        </>
    )
}

export default HomePage
