import { Button, Grid } from '@mui/material'
import { addDonation } from './Functions/DonateTotalMutation'
import DonateForm from './Components/DonateForm'

const AdminPage = () => {
    return (
        <Grid
            container
            sx={{ height: '100%' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <DonateForm />
            </Grid>
        </Grid>
    )
}

export default AdminPage
