import { Grid } from '@mui/material'
import { GenericCardHeader } from '../../../../Components/GenericCardComponents'

type Prop = {
    smDown?: boolean
    text?: string
}
const Banner = ({ smDown, text }: Prop) => {
    return (
        <Grid
            container
            sx={{
                height: smDown ? undefined : '20%',
                pt: smDown ? 4 : undefined,
                backgroundColor: 'white',
            }}
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
        >
            <Grid item>
                <GenericCardHeader title={text || 'June 2024'} fontSize={40} />
            </Grid>
        </Grid>
    )
}

export default Banner
