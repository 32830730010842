import { AppBar, Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { AboutCard, ContactCard, LogoCard } from '../Pages/HomePage/BottomCards'

const Footer = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <AppBar
            position="static"
            sx={{
                marginTop: smDown ? 1 : 0,
                backgroundColor: '#DDE4E6',
                color: (theme) => theme.palette.common.black,
                padding: (theme) => theme.spacing(2),
                height: smDown ? undefined : (theme) => theme.spacing(25), // changes the height of the footer
            }}
        >
            <Toolbar disableGutters>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        lg={3}
                        xl={2}
                        sx={{
                            borderBottom: smDown ? 1 : 0,
                            borderColor: 'whitesmoke',
                        }}
                    >
                        {smDown ? <LogoCard /> : <AboutCard />}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        lg={3}
                        xl={2}
                        sx={{
                            borderBottom: smDown ? 1 : 0,
                            borderColor: 'whitesmoke',
                        }}
                    >
                        {smDown ? <AboutCard /> : <LogoCard />}
                    </Grid>

                    {/*   <Grid item xs={12} md={4} lg={3} xl={2}>
                        <ContactCard />
                    </Grid> */}
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default Footer
