import Layout from './Components/Layout'
import { Route, Routes } from 'react-router-dom'
import HomePage from './Pages/HomePage/HomePage'
import DonatePage from './Pages/DonatePage/DonatePage'
import ErrorPage from './Pages/ErrorPage'
import AboutPage from './Pages/AboutPage/AboutUs/AboutPage'
import AboutFounder from './Pages/AboutPage/AboutFounder/AboutFounder'
import AboutChildren from './Pages/AboutPage/AboutChildren/AboutChildrenPage'
import UpdatesPage from './Pages/UpdatesPage/UpdatesPage'
import AboutOrphangesPage from './Pages/AboutPage/AboutOrphanges/AboutOrphangesPage'
import ThankYouPage from './Pages/ThankPage/thankyoupage'
import AdminPage from './Pages/AdminPage/AdminPage'

function App() {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="donate" element={<DonatePage />} />
                <Route path="about/us" element={<AboutPage />} />
                <Route path="about/founder" element={<AboutFounder />} />
                <Route path="about/orphans" element={<AboutChildren />} />
                <Route
                    path="about/orphanages"
                    element={<AboutOrphangesPage />}
                />
                <Route path="updates" element={<UpdatesPage />} />
                <Route path="thankyou" element={<ThankYouPage />} />
                <Route path="admin" element={<AdminPage />} />
                <Route path="/*" element={<ErrorPage />} />
            </Routes>
        </Layout>
    )
}

export default App
