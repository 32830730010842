import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

type ButtonProp = {
    title: string
    routePath: string
    color?: string
    width?: string
    download?: boolean
}

export const ThemedRouteButton = ({
    title,
    routePath,
    color,
    width,
    download,
}: ButtonProp) => {
    const navigate = useNavigate()

    const handleRoute = (route: string) => {
        navigate(`${route}`)
    }

    const handleDownload = () => {
        const link = document.createElement('a')
        link.href = `/Donated_Items_June_2024.pdf`
        link.download = 'june_2024_donated_items.pdf'
        link.click()
    }

    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: color || '#DDE4E6',
                color: 'black',
                width: width || '100%',
            }}
            onClick={() =>
                download ? handleDownload() : handleRoute(routePath)
            }
        >
            {title}
        </Button>
    )
}
