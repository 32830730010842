import { Controller, useForm } from 'react-hook-form'
import { DonateData } from '../../DonatePage/Functions/DonateTypes'
import { TextField } from '@mui/material'

type NameKey = Extract<keyof DonateData, string>

type FormTextProps = {
    name: NameKey
}

const FormText = ({ name }: FormTextProps) => {
    const { control } = useForm<DonateData>()

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
                <TextField
                    {...field}
                    label="Donation Value"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
            )}
        />
    )
}

export default FormText
