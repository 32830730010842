import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ThemedRouteButton } from '../../Components/Button'
import { DonateData } from '../DonatePage/Functions/DonateTypes'
import { useEffect, useState } from 'react'

type Props = {
    data?: DonateData | undefined
}

const TotalDonations = ({ data }: Props) => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'))

    const [donationTotal, setTotal] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (data) {
            setTotal(data?.donateValue)
        }
    }, [data])

    return (
        <Grid
            container
            sx={{
                height: smMatch ? (onlySm ? '100%' : '60%') : '100%',
            }}
            alignItems={'center'}
        >
            <Grid
                item
                xs={12}
                sx={{
                    height: smMatch ? '100%' : '60%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#DDE4E6',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Bodoni',
                        fontStyle: 'italic',
                        fontSize: smMatch ? (onlySm ? 60 : 40) : undefined,
                    }}
                    variant={smMatch ? undefined : 'h2'}
                >
                    Donation Status
                </Typography>
                <br />
                <Typography sx={{ fontFamily: 'sans-serif' }} variant={'h6'}>
                    So far, we have collected...
                </Typography>
                <br />
                <Typography
                    sx={{
                        fontFamily: 'Bodoni',
                        overflow: 'auto',
                        padding: onlySm ? 2 : 4,
                        backgroundColor: 'white',
                    }}
                    variant={onlySm ? 'h5' : 'h3'}
                >
                    {
                        data
                            ? `$${donationTotal}`
                            : '$3821.85' /* 'Still calculating...' */
                    }
                </Typography>
                <br />
                <ThemedRouteButton
                    title="Get Updates"
                    routePath="updates"
                    width={smMatch ? '72%' : '20%'}
                    color="whitesmoke"
                />
            </Grid>
        </Grid>
    )
}

export default TotalDonations
