import { Card, CardContent, Grid } from '@mui/material'
import busDrawing from '../../../../Resources/Orphans/OrphanBusDrawing.png'
import rachaelInRoom from '../../../../Resources/Racheal/6.24.RachaelIntheRoom.png'
import givingSupplies from '../../../../Resources/Orphanages/giving_orphans_food.png'
import packingSupplies from '../../../../Resources/Orphanages/packing_food.png'
import orphan_in_bed from '../../../../Resources/Orphans/orphan_reaching_out.png'
import fullSupplies from '../../../../Resources/Orphanages/full_supplies.png'
import waitingRoom from '../../../../Resources/Orphanages/orphan_waiting_room.png'
import StoryBoard from './StoryBoard'
import { primaryColor } from '../../../../Components/pallete'

const VisitSummary = () => {
    return (
        <Grid container sx={{ backgroundColor: primaryColor }}>
            <Grid item xs={12} container>
                <StoryBoard
                    image={rachaelInRoom}
                    text={firstText}
                    imageLeft={false}
                />
            </Grid>
            <Grid item xs={12} container>
                <StoryBoard
                    image={fullSupplies}
                    text={secondText}
                    imageLeft={true}
                />
            </Grid>
            <Grid item xs={12} container>
                <StoryBoard
                    image={givingSupplies}
                    text={thirdText}
                    imageLeft={false}
                />
            </Grid>
            <Grid item xs={12} container>
                <StoryBoard
                    image={packingSupplies}
                    text={fourthText}
                    imageLeft={true}
                />
            </Grid>
            <Grid item xs={12} container>
                <StoryBoard
                    image={waitingRoom}
                    text={fifthText}
                    imageLeft={false}
                />
            </Grid>
            <Grid item xs={12} container>
                <StoryBoard
                    image={orphan_in_bed}
                    text={sixthText}
                    imageLeft={true}
                />
            </Grid>
            <Grid item xs={12} container>
                <StoryBoard
                    image={busDrawing}
                    text={seventhText}
                    imageLeft={false}
                />
            </Grid>
        </Grid>
    )
}

export default VisitSummary

const firstText = `Visiting the orphanages in Vietnam began with a simple phone call. 
I reached out to several orphanages to better understand their needs. 
Through these conversations, I learned about their daily struggles 
and the essential items they lacked, and I compiled a list of what they needed most.`

const secondText = `With the help of my relatives in Vietnam, I was able to work with 
local vendors to procure the items on the list, successfully gathering 
hundreds of goods with the money I had raised. I also contacted a 
transportation company to arrange the trip to the orphanages, ensuring we could transport the items.`

const thirdText = `With a plan in place, I coordinated with the orphanages for a visit day, 
and simultaneously reached out to friends, relatives, and local volunteers to join me on the journey.`

const fourthText = `I remember the excitement building as the visit date approached. 
On the day of the trip, my friends and relatives helped load the bus with essentials, 
as well as packed goodie bags filled with milk, candy, and bakery items for the children. 
When we finally arrived, the staff welcomed us with smiles, and as we unloaded the bus, 
the children eagerly came out to help.`

const fifthText = `The staff led us into a room full of kids, and as we began to unpack 
the goodie bags and distribute the treats, the atmosphere in the orphanage transformed. 
Laughter and joy filled the room as the kids excitedly reached for the goodie bags.`

const sixthText = `Seeing the children’s excitement was truly heartwarming. Even the kids 
who couldn’t speak made an effort to express their happiness through writing and drawing. 
Their faces lit up with huge smiles, showing how glad they were that we visited. 
In that moment, I felt that all my efforts were worthwhile and that even small acts of kindness 
could make a significant difference in the lives of less fortunate children.`

const seventhText = `What I thought was a small contribution had a profound impact on these kids, 
and I realized that hundreds of children's lives were touched in ways I hadn’t expected.`
