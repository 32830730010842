import { Facebook, Instagram, X } from '@mui/icons-material'
import { Grid, Link, Toolbar, Typography } from '@mui/material'
import newLogo from '../Resources/newLogo.png'
import { useNavigate, useLocation } from 'react-router-dom'

const SocialNavigationBar = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const isHome = location.pathname === '/'

    const handleRoute = () => {
        navigate('/')
    }

    return (
        <Toolbar
            sx={{ backgroundColor: '#DDE4E6', color: 'black', height: 20 }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
            >
                <Grid item xs={6}>
                    <div onClick={handleRoute}>
                        <img
                            src={newLogo}
                            alt="Orphanges Of Vietnam"
                            height={55}
                            style={{
                                cursor: isHome ? undefined : 'pointer',
                            }}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{ textAlign: 'right' }}
                    container
                    justifyContent={'flex-end'}
                    alignItems="center"
                >
                    {/* <Grid item sx={{ paddingRight: 2 }}>
                        <Typography sx={{ fontFamily: 'Bodoni', fontSize: 18 }}>
                            Follow Us:
                        </Typography>
                    </Grid>
                    <SocialLogos /> */}
                </Grid>
            </Grid>
        </Toolbar>
    )
}

export default SocialNavigationBar

const SocialLogos = () => {
    return (
        <>
            <Grid item sx={{ paddingRight: 1 }}>
                <Link href="https://twitter.com/?lang=en">
                    <X fontSize="medium" sx={{ color: 'black' }} />
                </Link>
            </Grid>
            <Grid item sx={{ paddingRight: 1 }}>
                <Link href="https://www.facebook.com/">
                    <Facebook fontSize="medium" sx={{ color: 'black' }} />
                </Link>
            </Grid>
            <Grid item>
                <Link href="https://www.instagram.com/">
                    <Instagram fontSize="medium" sx={{ color: 'black' }} />
                </Link>
            </Grid>
        </>
    )
}
