
export enum CardPosition {
    'right' = 10,
    'center' = 12
}

export type CardSize = 'small' | 'medium' | 'large';

export type CardDimensions = {
    cardWidth: string| undefined;
    cardHeight: string | undefined;
}

export type CardProp = {
    cardSize?: CardSize
    cardText?: string
    titleProps?: {
        title: string | JSX.Element
        size?: TypographyVariant
    }
    cardTransparency?: 'solid' | 'transparent'
    buttonProps?: {
        title: string
        route: string
        download?: boolean
    }
    seperate?: boolean;
}

export type OpeningPageProps = {
    image: string
    cardPosition?: CardPosition
    cardProps?: CardProp
    seperate?: boolean
}

export type TypographyVariant = 
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'inherit';