import { Grid, useMediaQuery, useTheme } from '@mui/material'
import OpeningCard from './DisplayCard'
import { primaryColor } from '../pallete'
import OpeningPageXS from './OpeningPageXS'
import { CardPosition, OpeningPageProps } from './OpenerTypes'
import { useThemeBreakPoints } from '../../Functions/useBreakPointDebugger'
import SeperatePage from './Seperate/SeperatePage'

/**
 * A section that can be rendered in a page
 * displaying a photo and a card displayed on top
 *
 *
 * @param image - The path to the image
 * @param cardPosition - Using ENUM type CardPosition for placement of the card
 * @param cardProps - Details for the card that would be rendered on top of the image
 * @param seperate - If TRUE, instead of a card on top of a background, the image and card will be side by side
 *
 * @returns
 */
const OpeningPage = ({
    image,
    cardPosition,
    cardProps,
    seperate,
}: OpeningPageProps) => {
    const theme = useTheme()
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
    const { xsMatch, smMatch, mdMatch } = useThemeBreakPoints()

    const { cardSize, buttonProps, cardTransparency, titleProps, cardText } =
        cardProps || {}

    return (
        <Grid
            container
            sx={{
                height: xsMatch ? undefined : '100%',
                mb: xsMatch ? 2 : 0,
            }}
        >
            {seperate ? (
                <SeperatePage image={image} cardProps={cardProps || {}} />
            ) : xsMatch ? (
                <OpeningPageXS image={image} />
            ) : (
                <Grid
                    item
                    xs={12}
                    sx={{
                        height: smMatch ? '100%' : undefined,
                        backgroundImage: `url(${image})`,
                        backgroundSize: lgUp ? 'cover' : 'fit',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: primaryColor,
                        backgroundPosition: mdMatch
                            ? 'right 1px center'
                            : undefined,
                    }}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        md={cardPosition || 12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent:
                                smMatch || cardPosition === CardPosition.center
                                    ? 'center'
                                    : 'flex-end',
                        }}
                    >
                        <OpeningCard
                            cardSize={cardSize}
                            cardText={cardText}
                            buttonProps={buttonProps}
                            cardTransparency={cardTransparency}
                            titleProps={titleProps}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default OpeningPage
