import { useMediaQuery, useTheme } from '@mui/material'
import DonationInformationPage from './DonationInformationPage'
import { useGetDonateDate } from './Functions/useGetDonateData'

const DonatePage = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <>
            <DonationInformationPage smDown={smDown} />
        </>
    )
}

export default DonatePage
