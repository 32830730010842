import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ThemedRouteButton } from '../../Components/Button'
import blueBackground from '../../Resources/blueBackground.jpg'

const UpdatesPage = () => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Grid
            container
            sx={{
                height: smMatch ? '75%' : '100%',
                backgroundImage: `url(${blueBackground})`,
                backgroundSize: 'cover',
            }}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Grid
                item
                xs={9}
                lg={6}
                sx={{
                    height: '70%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'whitesmoke',
                    padding: smMatch ? 1 : 2,
                }}
            >
                {smMatch ? (
                    <>
                        <Typography
                            sx={{
                                fontFamily: 'times new roman',
                                fontSize: smMatch ? 25 : undefined,
                            }}
                            variant={smMatch ? undefined : 'h3'}
                        >
                            This Week's Updates
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            sx={{ fontFamily: 'Bodoni' }}
                            variant={smMatch ? 'h4' : 'h3'}
                        >
                            Updates
                        </Typography>
                        <br />
                        <Typography
                            sx={{ fontFamily: 'times new roman' }}
                            variant={'h5'}
                        >
                            This week's news
                        </Typography>
                    </>
                )}

                <br />
                <Typography
                    sx={{
                        fontFamily: 'times new roman',
                        overflow: 'auto',
                        fontWeight: 'bold',
                        alignItems: 'center',
                    }}
                    variant={'h6'}
                >
                    In June 2024, we visited three orphanages - Mai Em Thien
                    Duyen, Co So Khuyet Tat Thien Phuoc Nhan AI, and Mai Am How
                    Hong -Where we provided essential supplies such as rice,
                    milk, sugar, cooking items, etc. We used all the donated
                    funds to meet the basic needs of the children at these three
                    orphanages
                </Typography>
                <br />
                <ThemedRouteButton
                    title="See more here"
                    routePath="updates"
                    width={smMatch ? '60%' : '40%'}
                />
            </Grid>
        </Grid>
    )
}

export default UpdatesPage
