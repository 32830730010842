import { Grid, Typography } from '@mui/material'
import { ThemedRouteButton } from '../../Button'

const OovnXSCard = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h4" fontFamily={'Bodoni'}>
                    Your Help Helps Others
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <ThemedRouteButton title="Donate" routePath="donate" />
            </Grid>
        </Grid>
    )
}

export default OovnXSCard
