import { Grid, useMediaQuery, useTheme } from '@mui/material'
import OpeningCard from './DisplayCard'
import { primaryColor } from '../pallete'
import { OpeningPageProps } from './OpenerTypes'

const OpeningPageXS = ({ image }: OpeningPageProps) => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))

    const cardSize = smMatch ? undefined : '460px'
    const cardHeight = smMatch ? undefined : '300px'

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{
                    height: 300,
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: primaryColor,
                }}
                container
                justifyContent={'flex-end'}
                alignItems={'flex-end'}
            ></Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                }}
            >
                <OpeningCard cardSize={'small'} />
            </Grid>
        </>
    )
}

export default OpeningPageXS
