import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from '@mui/material'
import { ThemedRouteButton } from '../Button'
import { useThemeBreakPoints } from '../../Functions/useBreakPointDebugger'
import { CardProp } from './OpenerTypes'
import OovnXSCard from './OOVN/OOVN_xs_card'
import { getCardDimensions } from './Functions/OpenerPageUtils'

const OpeningCard = ({
    cardSize,
    cardText,
    titleProps,
    cardTransparency,
    buttonProps,
    seperate,
}: CardProp) => {
    const { xsMatch, smMatch } = useThemeBreakPoints()
    const { cardHeight, cardWidth } = getCardDimensions(cardSize, seperate)

    return (
        <>
            {xsMatch ? (
                <OovnXSCard />
            ) : (
                <Card
                    sx={{
                        height: cardHeight,
                        width: cardWidth,
                        backgroundColor:
                            cardTransparency === 'transparent'
                                ? 'rgba(255, 255, 255, 0.85)' // White color with 80% opacity
                                : 'whitesmoke',
                        textAlign: 'center',
                        margin: smMatch ? 2 : undefined,
                    }}
                    elevation={seperate ? 5 : 0}
                >
                    <CardHeader
                        title={titleProps?.title || ''}
                        titleTypographyProps={{
                            variant:
                                titleProps?.size ||
                                (cardSize === 'large' ? 'h2' : 'h4'),
                            sx: {
                                overflowWrap: 'break-word',
                                fontFamily: 'Canva Sans',
                                textTransform: 'capitalize',
                                paddingTop: 4,
                                fontWeight: 'bold',
                            },
                        }}
                    />
                    <CardContent>
                        {cardText && (
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: 'times-new-roman',
                                    fontSize:
                                        cardSize === 'small'
                                            ? 18
                                            : cardSize === 'medium'
                                            ? 22
                                            : 28,
                                    overflow: 'auto',
                                }}
                                gutterBottom
                            >
                                {cardText}
                            </Typography>
                        )}
                    </CardContent>
                    <CardActions>
                        {buttonProps && (
                            <ThemedRouteButton
                                title={buttonProps?.title}
                                routePath={buttonProps.route}
                                download={buttonProps.download}
                            />
                        )}
                    </CardActions>
                </Card>
            )}
        </>
    )
}

export default OpeningCard
