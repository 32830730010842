import littleBoy from '../../Resources/Orphans/orange_shirt_orphan.png'
import { OpeningPage } from '../../Components/OpenerPage'
import { CardPosition } from '../../Components/OpenerPage/OpenerTypes'

const ImagePage = () => {
    return (
        <OpeningPage
            image={littleBoy}
            cardPosition={CardPosition.right}
            cardProps={{
                cardSize: 'small',
                cardTransparency: 'transparent',
                buttonProps: {
                    title: 'Donate',
                    route: 'donate',
                },
                titleProps: {
                    title: (
                        <>
                            Your help
                            <br />
                            Helps Others
                        </>
                    ),
                    size: 'h3',
                },
            }}
        />
    )
}

export default ImagePage
