import { CardDimensions, CardSize } from "../OpenerTypes"

export const getCardDimensions = (cardSize: CardSize | undefined, seperate?: boolean) => {
    let dimensions: CardDimensions

    if (cardSize) {
        switch (cardSize) {
            case 'small':
                dimensions = {
                    cardWidth: '460px',
                    cardHeight: '300px',
                }
                break
            case 'medium':
                dimensions = {
                    cardWidth: '450px',
                    cardHeight: '405px',
                }
                break
            case 'large':
                dimensions = {
                    cardWidth: seperate ? '700px' : '500px',
                    cardHeight: '500px',
                }
                break
            default:
                dimensions = {
                    cardWidth: '450px',
                    cardHeight: '400px',
                }
                break
        }
    } else {
        dimensions = {
            cardWidth: undefined,
            cardHeight: undefined,
        }
    }

    return dimensions
}
